import React from "react"
import { Link } from "gatsby"
import * as styles from "../styles/blocks.module.css"
import { useLangContext } from "../context/langContext"
import defaultImg from "../images/news-img-default.jpg"

const NewsItem = ({ title, date, link, image = defaultImg, imageAlt = "" }) => {
    const { lang } = useLangContext()
    return (
        <div className={`px-4 pt-4 pb-8 ${styles.newsItem}`}>
            <Link className="image-wrap" to={link}>
                <img src={image} alt={imageAlt} />
            </Link>
            <h4 className="font-bold mt-4">
                <Link to={link}>{title}</Link>
            </h4>
            <span className="block text-sm mb-4">{date}</span>
            <Link className={styles.readmore} to={link}>
                {lang === "en" ? "read more" : "baca selengkapnya"}
            </Link>
        </div>
    )
}

export default NewsItem
