import * as React from "react"
import { AnimatePresence, motion } from "framer-motion"
import { Button } from "../components/buttons"
// import defaultImg from "../images/popup-img-default.jpg"

import * as styles from "../styles/popup.module.css"
import { graphql, Link, useStaticQuery } from "gatsby"

const PopUp = ({ isVisible, closePopUp }) => {
    const data = useStaticQuery(graphql`
        {
            wpPage(slug: { eq: "home" }) {
                pop_up {
                    listPopUp {
                        ... on WpPage_PopUp_ListPopUp_PopUpContent {
                            startDate
                            endDate
                            title
                            description
                            image {
                                sourceUrl
                            }
                            ctaLabel
                            ctaLink {
                                target
                                title
                                url
                            }
                        }
                    }
                }
            }
        }
    `)
    const escClosePopUp = e => {
        if (e.keyCode === 27) {
            closePopUp()
        }
    }
    const listPopUp = data?.wpPage?.pop_up?.listPopUp
    const activePopUp = []
    listPopUp.forEach(item => {
        const startDate = new Date(item.startDate).getTime()
        const endDate = new Date(item.endDate).getTime()
        const now = Date.now()
        if (now > startDate && now < endDate) {
            activePopUp.push(item)
        }
    })
    if (activePopUp.length == 0) return <></>
    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    inital={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 z-50"
                >
                    <div
                        className="overlay bg-opacity-50 bg-black absolute inset-0"
                        onClick={closePopUp}
                        role="layout"
                        onKeyDown={escClosePopUp}
                    >
                        {activePopUp.map((item, i) => {
                            return (
                                <div
                                    key={i}
                                    className={`flex flex-col-reverse lg:flex-row justify-center items-center mx-auto mt-4 md:lg-8 lg:mt-36 relative ${styles.popUp}`}
                                >
                                    <div
                                        className={`bg-white p-4 lg:p-10 ${styles.text}`}
                                    >
                                        <h2
                                            className={`font-serif text-4xl mb-8 ${styles.title}`}
                                        >
                                            {item.title}
                                        </h2>

                                        <div
                                            className="mb-8"
                                            dangerouslySetInnerHTML={{
                                                __html: item.description,
                                            }}
                                        ></div>
                                        <Button style={{ marginLeft: 0 }}>
                                            <Link
                                                to={item.ctaLink.url}
                                                target={item.ctaLink.target}
                                            >
                                                {item.ctaLabel}
                                            </Link>
                                        </Button>
                                    </div>
                                    <div
                                        className={styles.img}
                                        style={{
                                            backgroundImage: `url(${item.image.sourceUrl})`,
                                        }}
                                    >
                                        Image Caption
                                        <button
                                            onClick={closePopUp}
                                            className={styles.close}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default PopUp
