import React, { useRef, useState } from "react"
import Div100vh from "react-div-100vh"
import Slider from "react-slick"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Button } from "../components/buttons"
import NewsItem from "../components/newsItem"
import GridItem from "../components/gridItem"
import PopUp from "../components/popup"
import * as styles from "../styles/home.module.css"
import secondImage from "../images/home-second-img-new.png"
import masterplanImage from "../images/masterplan-bg.jpg"

const settings = {
    arrows: false,
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
}

const IndexPage = ({ data }) => {
    let coverStyle = {
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    }

    const slideRef = useRef()
    const goToPrev = () => {
        if (slideRef) slideRef.current.slickPrev()
    }
    const goToNext = () => {
        if (slideRef) slideRef.current.slickNext()
    }
    const [isPopUpVisible, setIsPopUpVisible] = useState(true)
    const closePopUp = () => setIsPopUpVisible(false)
    return (
        <Layout activeMenuId={1}>
            <Seo
                title="Home"
                description={data?.wpPage?.seo?.metaDesc}
                date={data?.wpPage?.date}
                img={data?.wpPage?.seo?.opengraphImage?.mediaItemUrl}
                imgHeight={
                    data?.wpPage?.seo?.opengraphImage?.mediaDetails?.height
                }
                imgWidth={
                    data?.wpPage?.seo?.opengraphImage?.mediaDetails?.width
                }
            />
            {data?.wpPage?.blocks.map((item, i) => {
                if (item?.name === "acf/cover-main") {
                    const coverMain = item?.coverMain
                    coverStyle = {
                        ...coverStyle,
                        backgroundImage: `url('${coverMain?.img?.mediaItemUrl}')`,
                    }

                    return (
                        <Div100vh style={coverStyle} key={i}>
                            <div className="h-full w-full p-8 flex justify-center items-center">
                                <div className={styles.banner}>
                                    <h2 className="text-7xl font-serif">
                                        {coverMain?.title}
                                    </h2>
                                    <p key={i} className="mt-8 mb-24">
                                        {coverMain?.text}
                                    </p>
                                    <Button style={{ marginLeft: 0 }}>
                                        <Link to="/about">
                                            More About Kota Wisata
                                        </Link>
                                    </Button>
                                </div>
                            </div>
                        </Div100vh>
                    )
                } else if (item?.name === "acf/col-headline-w-image-w-link") {
                    const data = item?.colHeadlineTextLinkImage
                    return (
                        <div
                            key={i}
                            className={`py-12 lg:py-24 ${styles.second}`}
                        >
                            <div className="container mx-auto">
                                <div className="px-4 lg:flex justify-center items-start">
                                    <div className="hidden lg:block w-1/4">
                                        <img
                                            src={secondImage}
                                            alt="Second Image"
                                        />
                                    </div>
                                    <div className="w-full lg:w-3/4">
                                        <div className="mb-4 flex lg:block justify-start items-start">
                                            <div className="block lg:hidden w-1/4">
                                                <img
                                                    src={secondImage}
                                                    alt="Second Image"
                                                />
                                            </div>
                                            <h2 className="w-3/4 lg:w-full font-serif text-5xl lg:text-7xl">
                                                {data?.title}
                                            </h2>
                                        </div>
                                        <p className="mb-16">{data?.text}</p>
                                        <div
                                            className={`${styles.secondLinks}`}
                                        >
                                            <div className="mb-8 relative">
                                                <h3 className="mb-4 text-xl font-bold font-serif">
                                                    The City
                                                </h3>
                                                <div className="relative mx-0 lg:mx-8 mt-4 home-carousel">
                                                    <Slider
                                                        ref={slideRef}
                                                        {...settings}
                                                    >
                                                        {data?.images.map(
                                                            (item, i) => {
                                                                return (
                                                                    <div
                                                                        key={i}
                                                                        className="slide-wrap"
                                                                    >
                                                                        <GridItem
                                                                            bgImage={
                                                                                item
                                                                                    ?.image
                                                                                    ?.mediaItemUrl
                                                                            }
                                                                            label={
                                                                                item?.label
                                                                            }
                                                                            link={
                                                                                item
                                                                                    ?.link
                                                                                    ?.url
                                                                            }
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                        )}
                                                    </Slider>
                                                    <button
                                                        onClick={goToPrev}
                                                        className={`${styles.cityBtn} ${styles.cityBtnPrev}`}
                                                    >
                                                        Previous
                                                    </button>
                                                    <button
                                                        onClick={goToNext}
                                                        className={`${styles.cityBtn} ${styles.cityBtnNext}`}
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                <h3 className="mb-4 text-xl font-serif font-bold">
                                                    Let's Explore
                                                </h3>
                                                <ul className="lg:flex justify-start items-start">
                                                    {data?.link.map(
                                                        (item, i) => {
                                                            return (
                                                                <li
                                                                    key={i}
                                                                    className="block lg:mr-8"
                                                                >
                                                                    <Link
                                                                        to={
                                                                            item
                                                                                ?.link
                                                                                ?.url
                                                                        }
                                                                    >
                                                                        {
                                                                            item?.text
                                                                        }
                                                                    </Link>
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                } else if (item?.name === "acf/event-slider") {
                    const data = item?.eventSlider
                    return (
                        <>
                            <div
                                className={`flex justify-center items-center ${styles.masterPlan}`}
                                style={{
                                    backgroundImage: `url(${masterplanImage})`,
                                }}
                            >
                                <div className={styles.masterPlanText}>
                                    <h2 className="text-center font-serif text-white text-5xl lg:text-7xl">
                                        Masterplan
                                    </h2>
                                    <p className="my-8 text-white text-center mx-8 lg:mx-0">
                                        Masterplan descriptionNunc aliquet,
                                        augue nec adipiscing interdum, lacus
                                        tellus malesuada massa, quis varius mi
                                        purus non odio.
                                    </p>
                                    <Button style={{ marginLeft: 0 }}>
                                        <Link
                                            to="/masterplan"
                                            className={styles.masterPlanBtn}
                                        >
                                            View Our Masterplan
                                        </Link>
                                    </Button>
                                </div>
                            </div>
                            <div
                                key={i}
                                className={`pt-12 pb-0 lg:py-24 ${styles.third}`}
                            >
                                <div className="container mx-auto">
                                    <div className="px-4 lg:flex justify-between items-center mb-12 lg:mb-24">
                                        <h2 className="text-center lg:text-left font-serif text-5xl lg:text-7xl">
                                            {data?.title}
                                        </h2>
                                        <div className="hidden lg:block">
                                            <Link
                                                className={styles.viewMore}
                                                to={data?.link?.url}
                                            >
                                                {data?.link?.text}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-0">
                                        {data?.events.map((item, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className="mb-8 lg:mb-0"
                                                >
                                                    <NewsItem
                                                        image={
                                                            item?.img
                                                                ?.mediaItemUrl
                                                        }
                                                        imageAlt={
                                                            item?.img?.altText
                                                        }
                                                        title={item?.title}
                                                        link={item?.url}
                                                        date={item?.text}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="mt-4 block lg:hidden text-center">
                                        <Link
                                            className={styles.viewMoreMobile}
                                            to="/news"
                                        >
                                            <span>View more</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            })}
            <PopUp isVisible={isPopUpVisible} closePopUp={closePopUp} />
        </Layout>
    )
}

export const query = graphql`
    {
        wpPage(slug: { eq: "home" }) {
            blocks {
                name
                ... on WpAcfCoverMainBlock {
                    coverMain {
                        img {
                            altText
                            mediaItemUrl
                        }
                        imgOverlay
                        label
                        title
                        text
                        buttons {
                            text
                            link {
                                target
                                url
                            }
                        }
                    }
                }
                ... on WpAcfColHeadlineWImageWLinkBlock {
                    colHeadlineTextLinkImage {
                        text
                        title
                        images {
                            label
                            link {
                                url
                                title
                            }
                            image {
                                altText
                                mediaItemUrl
                            }
                        }
                        link {
                            link {
                                url
                            }
                            text
                        }
                    }
                    name
                }
                ... on WpAcfEventSliderBlock {
                    name
                    eventSlider {
                        title
                        link {
                            url
                            text
                        }
                        events {
                            img {
                                altText
                                mediaItemUrl
                            }
                            url
                            text
                            title
                        }
                    }
                }
            }
            slug
            title
            date
            seo {
                title
                metaDesc
                opengraphImage {
                    id
                    mediaItemUrl
                    mediaDetails {
                        height
                        width
                    }
                }
            }
        }
    }
`

export default IndexPage
