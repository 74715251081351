// extracted by mini-css-extract-plugin
export var cover = "home-module--cover--3NHOV";
export var banner = "home-module--banner--SsSyv";
export var second = "home-module--second--3sNB_";
export var secondLinks = "home-module--secondLinks--2yDJP";
export var third = "home-module--third--24GOg";
export var viewMore = "home-module--viewMore--2y7FP";
export var viewMoreMobile = "home-module--viewMoreMobile--1AWNe";
export var cityBtn = "home-module--cityBtn--1qouQ";
export var cityBtnPrev = "home-module--cityBtnPrev--3mtl7";
export var cityBtnNext = "home-module--cityBtnNext--UC517";
export var masterPlan = "home-module--masterPlan--SwjLh";
export var masterPlanText = "home-module--masterPlanText--KW5wa";
export var masterPlanBtn = "home-module--masterPlanBtn--s9egX";